import React from "react"
import propTypes from "prop-types"
import { guideContainer } from "../../utils/styles"

const styles = {
  quickLinksContainer: theme => ({
    display: `flex`,
    position: `relative`,
    zIndex: 1,
    justifyContent: `flex-start`,
    [theme.mediaQueries.desktop]: {
      justifyContent: `flex-end`,
    },
  }),
  quickLinks: theme => ({
    marginRight: 0,
    marginTop: theme.space[10],
    [theme.mediaQueries.desktop]: {
      marginRight: -200,
      marginTop: -25,
    },
  }),
  quickLinksTitle: theme => ({
    fontSize: theme.fontSizes[1],
    color: theme.colors.gatsby,
    fontFamily: theme.fonts.system,
    letterSpacing: `0.125em`,
    marginTop: 0,
    marginBottom: theme.space[4],
    textTransform: `uppercase`,
    [theme.mediaQueries.desktop]: {
      fontSize: theme.fontSizes[0],
      color: theme.colors.lilac,
    },
  }),
  quickLinksTitleDivider: theme => ({
    height: 1,
    width: theme.space[8],
    backgroundColor: theme.colors.gatsby,
    marginBottom: theme.space[7],
    [theme.mediaQueries.desktop]: {
      backgroundColor: theme.colors.lilac,
      marginBottom: theme.space[4],
    },
  }),
  quickLinksList: theme => ({
    listStyle: `none`,
    padding: 0,
    margin: 0,
    display: `flex`,
    justifyContent: `flex-start`,
    [theme.mediaQueries.desktop]: {
      display: `block`,
    },
  }),
  quickLinkListItem: theme => ({
    marginRight: theme.space[7],
    [theme.mediaQueries.desktop]: {
      marginBottom: theme.space[2],
      marginRight: 0,
    },
  }),
  quickLink: theme => ({
    color: theme.colors.grey[50],
    textDecoration: `none`,
    [theme.mediaQueries.desktop]: {
      fontSize: theme.fontSizes[1],
    },
    "&:hover": {
      color: theme.colors.gatsby,
    },
  }),
}

const QuickLinks = ({ quickLinks }) => (
  <div css={theme => [styles.quickLinksContainer(theme), guideContainer]}>
    <div css={styles.quickLinks}>
      <h4 css={styles.quickLinksTitle}>Quick Links</h4>
      <div css={styles.quickLinksTitleDivider} />
      <ul css={styles.quickLinksList}>
        {quickLinks.map(link => (
          <li key={link.displayTitle} css={styles.quickLinkListItem}>
            <a href={link.url} css={styles.quickLink}>
              {link.displayTitle}
            </a>
          </li>
        ))}
      </ul>
    </div>
  </div>
)

QuickLinks.propTypes = {
  quickLinks: propTypes.arrayOf(propTypes.object).isRequired,
}

export default QuickLinks
